import React, { useEffect, useMemo, useRef, useState } from "react";
import "../index.css";
import { widget } from "../../../charting_library/charting_library.esm";
import Datafeed from "../dataFeedClass.js";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { WidthProvider, Responsive } from "react-grid-layout";
import { Tooltip } from "@material-ui/core";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import GridTickerSearch from "./GridTickerSearch.js";
import { Button } from "@mui/material";
import TrashIcon from "../../../assets/images/trash.png";
import useDebounce from "../../OptionScanner/useDebounce.js";
import { searchLoad } from "../../../appRedux/ducks/search.js";
import useAuth from "../../../contexts/Auth/useAuth.js";
import { apiURL, isInternalWS } from "../../../appRedux/actions/helpers.js";
import { updateLayoutLoad } from "../../../appRedux/ducks/updateLayout.js";
import defaultLayout from "../layout.js";
import ResizeHandle from "./ResizeHandle.js";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ConditionalWrapper } from "../../common/Layout/PrivateLayout/SidebarContent.js";
const ReactGridLayout = WidthProvider(Responsive);
export function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function FavoritesContainer({
  candleDefaults,
  themeMode,
  updateLayout,
  searchedSymbols,
  loading,
  searchAllSymbols,
}) {
  // const classes = useStyles()
  const [layouts, setLayouts] = useState(defaultLayout);
  const [newtickerAdded, setNewTickerAdded] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);
  const [symbol, setSymbol] = useState("");
  const [open, setOpen] = useState(false);
  const debouncedSymbol = useDebounce(symbol, 500);
  const [j, setJ] = useState(0);
  const { userData, fetchUser, userId } = useAuth();
  const [widgetRefs, setWidgetRefs] = useState([]);
  const widgetRefsRef = useRef(null);
  const maxCharts = useMemo(() => {
    return layouts.lg.length === 6;
  }, [layouts]);
  const onLayoutChange = (layout) => {
    setJ((prev) => prev + 1);
    setLayouts((prev) => ({
      ...prev,
      lg: layout.map((item) => {
        const existingItem = prev.lg.find((x) => x.i === item.i);
        return {
          ...existingItem,
          ...item,
        };
      }),
    }));
    setNewTickerAdded(false);
  };

  useEffect(() => {
    searchAllSymbols(debouncedSymbol);
  }, [debouncedSymbol]);
  useEffect(() => {
    widgetRefsRef.current = widgetRefs;
  }, [widgetRefs]);
  const indicators = candleDefaults?.defaultIndicators || [];
  const resolution = candleDefaults.defaultResolution;
  const candleType = candleDefaults.candleType;
  const settingsMemo = useMemo(() => {
    return `${resolution},${indicators},${candleType}`;
  }, [resolution, indicators, candleType]);
  const chartSaveMode = candleDefaults?.chartSaveMode || 0;
  const disabled_features = [
    "use_localstorage_for_settings",
    "display_market_status",
    "popup_hints",
    "header_screenshot",
    // "widget_logo",
  ];
  let subSet = useMemo(() => {
    let clone = userData?.layouts ? userData?.layouts?.lg : defaultLayout.lg;
    if (newtickerAdded) {
      return layouts.lg;
    } else {
      return clone;
    }
  }, [userData, newtickerAdded]);

  useEffect(() => {
    if (userData?.layouts) {
      setJ((prev) => prev + 1);
      setLayouts(userData?.layouts);
    }
  }, [userData]);

  useEffect(() => {
    //   getFavorites();
    return () => fetchUser();
  }, []);

  useEffect(() => {
    // if its the first render and userData is populated but there is no user layouts saved, then default the user obj's layouts to the default layouts
    if (j >= 4) updateLayout(layouts);
  }, [layouts]);

  useEffect(() => {
    if (j === 0 && !!userId && !userData?.layouts) updateLayout(defaultLayout);
  }, [userData, j]);

  useEffect(() => {
    setWidgetRefs([]);
    if (!!userData && subSet.length) {
      subSet.forEach((lay, index) => {
        const ticker = lay.ticker;
        const i = lay.i;
        const widgetOptions = {
          symbol: ticker,
          debug: false,
          interval: lay?.interval || resolution,
          symbol_search_request_delay: 1500,
          datafeed: new Datafeed(),
          container: `tv_chart_container${index}`, // here
          library_path: "/charting_library/",
          locale: getLanguageFromURL() || "en",
          time_frames: [
            { text: "5Y", resolution: "1D", description: "5 Years" },
            { text: "1Y", resolution: "1D", description: "1 Year" },
            { text: "6M", resolution: "1D", description: "6 Months" },
            { text: "3M", resolution: "1D", description: "3 Months" },
            { text: "1M", resolution: "1", description: "1 Month" },
            { text: "5D", resolution: "1", description: "5 Days" },
            { text: "1D", resolution: "1", description: "1 Day" },
          ].reverse(),
          header_widget_buttons_mode: "adaptive",
          disabled_features,
          enabled_features: [
            "side_toolbar_in_fullscreen_mode",
            "chart_style_hilo",
            "secondary_series_extend_time_scale",
            "header_in_fullscreen_mode",
            "hide_last_na_study_output",
            "seconds_resolution",
          ],
          load_last_chart: false,
          fullscreen: false,
          autosize: true,
          width: "100%",
          height: "100%",
          timezone: "America/New_York",
          client_id: "patternscanner",
          user_id: userId,
          auto_save_delay: 10,
          charts_storage_url: apiURL,
          charts_storage_api_version: chartSaveMode ? "1.3" : "1.1",
          theme: themeMode,
          overrides: {
            "mainSeriesProperties.showCountdown": true,
          },
          loading_screen: {
            backgroundColor: "#222222",
            foregroundColor: "#229712",
          },
        };
        const el = document.getElementById(`tv_chart_container${index}`);
        if (el) {
          const tvWidget = new widget(widgetOptions);
          tvWidget.onChartReady(() => {
            setWidgetRefs((prev) => {
              return [...prev, { tvWidget, i }];
            });
            tvWidget
              .chart()
              .onSymbolChanged()
              .subscribe(null, function (symbolData) {
                setJ((prev) => prev + 1);
                setLayouts((prev) => {
                  let clone = [...prev.lg];
                  clone[index] = {
                    ...clone[index],
                    ticker: symbolData.name,
                  };
                  return { lg: clone };
                });
              });
          });
        }
      });
    }
  }, [JSON.stringify(subSet), themeMode, userData, chartSaveMode]);
  useEffect(() => {
    if (widgetRefs.length > 0) {
      widgetRefs.forEach((widget) => {
        if (Object.keys(widget.tvWidget).length) {
          const widgetRef = widget.tvWidget;
          const chart = widgetRef.activeChart();
          const studies = chart.getAllStudies();
          // Check if volume study already exists
          const volumeStudy = studies.find(
            (study) => study.name.toLowerCase() === "volume",
          );
          // If volume study doesn't exist, remove all studies and create volume
          if (!volumeStudy) {
            chart.removeAllStudies();
            chart.createStudy("volume");
          } else {
            studies.forEach((study) => {
              if (study.name.toLowerCase() !== "volume") {
                chart.removeEntity(study.id); // Use removeEntity to remove other studies
              }
            });
          }
          // Set the chart type, resolution, and add other indicators
          chart.setChartType(Number(candleType));
          chart.setResolution(resolution);
          indicators?.forEach((indicator) => chart.createStudy(indicator));
        }
      });
    }
  }, [widgetRefs, settingsMemo]);

  useEffect(() => {
    return () => {
      // Make sure to capture the current value of widgetRefs in this scope
      const currentWidgetRefs = widgetRefsRef.current;
      currentWidgetRefs.forEach((widgetRef) => {
        const datafeed = widgetRef.tvWidget._options.datafeed;
        if (datafeed) {
          if (isInternalWS) {
            // Assuming isInternalWS is available in this scope
            datafeed.closeInternalWebSocket();
          } else {
            datafeed.closeExternalWebSocket();
          }
        }
      });
    };
  }, []); // Note the empty dependency array
  const onAddSymbol = (ticker) => {
    setLayouts((prevLayouts) => {
      const newItem = {
        ticker,
        w: 6,
        h: 3,
        x: 0,
        y: Infinity,
        i: prevLayouts.lg.length.toString(),
        moved: false,
        static: false,
      };
      return { ...prevLayouts, lg: [...prevLayouts.lg, newItem] };
    });
    setNewTickerAdded(true);
  };

  const handleRemove = (id) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = prevLayouts.lg
        .filter((item) => item.i !== id)
        .map((item, index) => ({
          ...item,
          i: index.toString(), // Adjust the indices to be sequential
        }));

      return { ...prevLayouts, lg: updatedLayouts };
    });

    setIsDraggable(true);
  };
  if (userData)
    return (
      <>
        <Helmet>
          <title>Chart Grid | TradeAlgo</title>
        </Helmet>
        <div className="grid-header">
          <div className="grid-title">
            Chart Grid
            <span>
              {" "}
              <Tooltip arrow={true} title={"chart grid tooltip"}>
                <ErrorOutlineSharpIcon
                  style={{ marginLeft: "5px", paddingBottom: "2px" }}
                />
              </Tooltip>
            </span>
          </div>
          <div className="grid-desc">
            Chart Grid lets you organise and pin the charts for the tickers that
            you are on the look out for during your trades
          </div>
          <ConditionalWrapper
            condition={maxCharts}
            wrapper={(children) => (
              <Tooltip
                placement="left"
                title={"Maximum amount of charts pinned"}
              >
                {children}
              </Tooltip>
            )}
          >
            <div className="search-wrapper">
              <GridTickerSearch
                disabled={maxCharts}
                open={open}
                setOpen={setOpen}
                valueLabelDisplay="on"
                options={searchedSymbols}
                tickers={[]}
                symbol={symbol}
                setSymbol={setSymbol}
                loading={loading}
                customMinWidth={250}
                onAddSymbol={onAddSymbol}
              />
              {/* <Button variant="contained" className="add-button">Add Chart</Button> */}
            </div>
          </ConditionalWrapper>
          <div className="bottom-wrapper">
            <span className="ticker-count">
              {layouts?.lg?.length}/6 Ticker Charts Pinned
            </span>
            <Button
              onClick={() => {
                if (isDraggable) {
                  setIsDraggable(false);
                } else {
                  setIsDraggable(true);
                }
              }}
              startIcon={
                isDraggable ? <img className="trash" src={TrashIcon} /> : <></>
              }
              className={isDraggable ? "rm-chart" : "done-chart"}
              variant="outlined"
            >
              {" "}
              {isDraggable ? "Remove Chart" : "Done"}{" "}
            </Button>
          </div>
        </div>
        <ReactGridLayout
          className="layout"
          compactType={"vertical"}
          isDraggable={isDraggable}
          layouts={layouts}
          isRearrangeable
          isResizable
          resizeHandles={["se", "sw", "ne", "nw"]}
          resizeHandle={(resizeHandleAxis, ref) => (
            <ResizeHandle handleAxis={resizeHandleAxis} ref={ref} />
          )}
          onLayoutChange={onLayoutChange}
          breakpoints={{ lg: 0 }}
        >
          {layouts.lg.map((lay, i) => {
            return (
              <div style={{ cursor: "grab" }} key={i}>
                {/* <img className="arrange-icon" onClick={(e) => {
                    e.stopPropagation()
                    setIsDraggable((prev) => !prev)
                  }} src={resize} alt="resize-icon" /> */}
                <div className="drag-wrapper">
                  {!isDraggable && (
                    <HighlightOffIcon
                      onClick={() => handleRemove(lay.i)}
                      className="remove-icon"
                    />
                  )}
                  <Tooltip title="drag to re-arrange">
                    <span className="drag-text" style={{ zIndex: 10000000 }}>
                      {lay.ticker}
                    </span>
                  </Tooltip>
                </div>
                <div
                  className={`TVChartContainer${i}`}
                  id={`tv_chart_container${i}`}
                />
              </div>
            );
          })}
        </ReactGridLayout>
        <br />
        <br />
        <br />
      </>
    );
  return null;
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  candleDefaults: state.userSettings.userSettings,
  searchedSymbols: state.searchSymbols.searchSymbols,
  loading: state.searchSymbols.symbolsLoading,
});

const dispatchToProps = (dispatch) => ({
  searchAllSymbols: (symbol) => dispatch(searchLoad(symbol)),
  updateLayout: (layouts, userData) =>
    dispatch(updateLayoutLoad(layouts, userData)),
});

export default connect(stateToProps, dispatchToProps)(FavoritesContainer);
