import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./WealthSeriesOverview.scss";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import AtlasLight from "../../assets/images/wealth-series/overview/atlas-light.png";
import AtlasDark from "../../assets/images/wealth-series/overview/atlas-dark.png";
import TitanLight from "../../assets/images/wealth-series/overview/titan-light.png";
import TitanDark from "../../assets/images/wealth-series/overview/titan-dark.png";
import EverestLight from "../../assets/images/wealth-series/overview/everest-light.png";
import EverestDark from "../../assets/images/wealth-series/overview/everest-dark.png";
import { portfoliosData, urlToNameMap } from "./portfoliosData";
import WSPortfolioItem from "./components/WSPortfolioItem/WSPortfolioItem";
import { Tab, Tabs } from "@mui/material";
import WSPortfolioActivities from "./components/WSPortfolioActivities/WSPortfolioActivities";
import WSTelegramCTA from "./components/WSTelegramCTA/WSTelegramCTA";
import WSLiveStream from "./components/WSLiveStream/WSLiveStream";
import WSBookMentorship from "./components/WSBookMentorship/WSBookMentorship";
import { portfolioLoad } from "../../appRedux/ducks/wealthPortfolio";
import { useMedia } from "react-media";
import moment from "moment";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { reversePortfolioHashMap } from "../WealthSeriesPortfolioPage/components/WealthSeriesPortfolioTickers/tickerData";
import { fetchSubscribedPortfoliosRequest } from "../../appRedux/ducks/portfolioSubscription";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import WSContactSupport from "./components/WSContactSupport/WSContactSupport";
import "moment-timezone";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const WealthSeriesOverview = ({
  themeMode,
  fetchPortfolioData,
  wealthPortfolios,
  loadingPortfolio,
  fetchSubscribed,
  listen,
}) => {
  const { series } = useParams();
  const [value, setValue] = useState(1);

  const handleChange = useCallback((event, newValue) => setValue(newValue), []);

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  useEffect(() => {
    fetchSubscribed();
  }, []);

  const last_updated = useMemo(() => {
    const filteredLastUpdatedDates = Object.keys(wealthPortfolios)
      .filter((key) => key.includes(series))
      .map((key) => wealthPortfolios[key]?.last_updated)
      .filter((date) => date && moment(date).isValid());
    // Find the newest (most recent) last_updated date
    let newestDate = null;
    if (filteredLastUpdatedDates.length > 0) {
      newestDate = filteredLastUpdatedDates.reduce((latest, current) => {
        return moment(current).isAfter(latest) ? current : latest;
      }, filteredLastUpdatedDates[0]);
    }
    return newestDate;
  }, [wealthPortfolios, series]);
  const tickers = useMemo(() => {
    let allocationTickers = new Set();
    if (series !== "everest") {
      allocationTickers = new Set(
        Object.keys(wealthPortfolios)
          .filter((key) => key.includes(series))
          .flatMap((key) =>
            wealthPortfolios[key]?.allocations
              .sort((a, b) => b.ticker_weightage - a.ticker_weightage)
              .slice(0, 5),
          )
          .map((allocation) => allocation.ticker),
      );
    }
    const adjustmentTickers = new Set(
      Object.keys(wealthPortfolios)
        .filter((key) => key.includes(series))
        .flatMap((portfolioKey) =>
          wealthPortfolios[portfolioKey].adjustments.flatMap((adj) =>
            Object.keys(adj.adjustments),
          ),
        ),
    );

    return new Set([...allocationTickers, ...adjustmentTickers]);
  }, [wealthPortfolios, series]);

  useEffect(() => {
    if (series !== "everest") {
      listen([...tickers]);
    }
    return () => listen([]);
  }, [tickers, series]);

  const tierLogo = useMemo(() => {
    if (series === "atlas") {
      return themeMode === "light" ? AtlasLight : AtlasDark;
    } else if (series === "titan") {
      return themeMode === "light" ? TitanLight : TitanDark;
    } else if (series === "everest") {
      return themeMode === "light" ? EverestLight : EverestDark;
    }
  }, [series, themeMode]);

  useEffect(() => {
    const ids = portfoliosData[series].ids;
    ids.forEach((el) => {
      const name = reversePortfolioHashMap[el];
      if (!wealthPortfolios[name]) {
        fetchPortfolioData(el, true);
      } else {
        if (!wealthPortfolios[name]?.sparkline?.length) {
          fetchPortfolioData(el, true);
        }
      }
    });
  }, [series]);

  const portfolioArr = useMemo(() => {
    return portfoliosData[series].portfolios;
  }, [series]);

  const telegramLink = useMemo(() => {
    if (series === "atlas") {
      return "https://t.me/+AUKA2wxPzNI0YmUx";
    } else if (series === "titan") {
      return "https://t.me/+avzWrOHzMrA4ZDQx";
    } else if (series === "everest") {
      return "https://t.me/+Uh2s1YztDcM2Nzgx";
    }
  }, [series]);

  const subTitle = useMemo(() => {
    if (series === "atlas") {
      return "Atlas Livestream";
    } else if (series === "titan") {
      return "Titan Livestream";
    } else if (series === "everest") {
      return "Everest Livestream";
    }
  }, [series]);

  const scheduleDate = useMemo(() => {
    if (series === "atlas") {
      return "Every Monday 4.30pm EST";
    } else if (series === "titan") {
      return "Every Tuesday 4.30pm EST";
    } else if (series === "everest") {
      return "Every Wednesday 4.30pm EST";
    }
  }, [series]);

  const description = useMemo(() => {
    if (series === "atlas") {
      return "Mike Andrews will conduct a weekly walkthrough of our Atlas portfolios and reveal the latest insights on the market.";
    } else if (series === "titan") {
      return "Mike Andrews will conduct a weekly walkthrough of our Titan portfolios, describing how to achieve maximum returns with minimal drawdowns through smart diversification.";
    } else if (series === "everest") {
      return "Mike Andrews will conduct a weekly walkthrough of our Everest portfolios, with advanced insights on wealth management using hedge fund strategies and smart diversification.";
    }
  }, [series]);

  const vimeoEventID = useMemo(() => {
    if (series === "atlas") {
      return "4788503";
    } else if (series === "titan") {
      return "4788514";
    } else if (series === "everest") {
      return "4788513";
    }
  }, [series]);

  // scroll to top smoothly on component rendering
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [series]);

  useEffect(() => {
    if (!isMobile && value === 0) {
      setValue(1);
    }
  }, [isMobile]);
  const { adjustments } = useMemo(() => {
    const transformed = Object.keys(wealthPortfolios)
      .filter((key) => key.includes(series))
      .flatMap((portfolioKey) => {
        const portfolio = wealthPortfolios[portfolioKey];
        const portfolioName = portfolio.name.replaceAll(" ", "-");
        return portfolio.adjustments.flatMap((adj) => {
          const date = adj.rebalance_date.split("T")[0];
          // Convert the ticker adjustments object into an array of objects
          return Object.entries(adj.adjustments).map(
            ([ticker, { old, new: newPrice, diff, name, last_price }]) => ({
              date,
              ticker,
              oldPrice: old,
              newPrice,
              diff,
              portfolioName: urlToNameMap[portfolioName],
              name,
              last_price,
              portfolioKey: portfolioName,
            }),
          );
        });
      })

      .reduce((acc, item) => {
        // Group by date
        if (!acc[item.date]) {
          acc[item.date] = [];
        }
        acc[item.date].push({
          ticker: item.ticker,
          diff: item.diff,
          newPrice: item.newPrice,
          oldPrice: item.oldPrice,
          portfolioName: item.portfolioName,
          name: item.name,
          last_price: item.last_price,
          portfolioKey: item.portfolioKey,
        });
        return acc;
      }, {});

    const result = Object.keys(transformed).map((date) => ({
      date,
      adjustments: transformed[date],
    }));
    return { adjustments: result };
  }, [series, wealthPortfolios]);

  return (
    <div className="ws-overview">
      <div className="ws-overview__header">
        <img src={tierLogo} alt="logo" className="ws-overview__logo" />
        <div className="ws-overview__header-text">
          {loadingPortfolio ? (
            <BeautifulSkeleton width={350} height={20} />
          ) : (
            <>
              Last updated: &nbsp;
              {moment(last_updated)
                .tz("America/New_York")
                .format("dddd, MMM D, YYYY h:mm A")}{" "}
              (EST)
            </>
          )}
        </div>
        {isMobile && <WSContactSupport isMobile />}
      </div>

      {isMobile && (
        <>
          <div className="ws-overview__tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              className="w-full ws-portfolio-ticker-info-tabs ws-overview-tabs--mobile"
            >
              <Tab label="Portfolios" value={0} />
              <Tab label="Portfolio Activity" value={1} />
              <Tab label="Livestream" value={2} />
              <Tab label="Book Mentorship Session" value={3} />
            </Tabs>
          </div>

          <div className="w-full ws-mobile-telegram-cta">
            <WSTelegramCTA link={telegramLink} className="ws-telegram-mobile" />
          </div>
        </>
      )}

      {!isMobile && (
        <>
          <div className="ws-overview-portfolios">
            {portfolioArr?.map((portfolio, index) => (
              <div
                key={index}
                className={`ws-overview-portfolio ${series === "everest" ? "ws-overview-portfolio--everest" : ""}`}
              >
                <WSPortfolioItem portfolio={portfolio} type={series} />
              </div>
            ))}
          </div>
          <div className="simulated">*Results are simulated</div>
          <div className="ws-overview__tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              className="w-full ws-portfolio-ticker-info-tabs"
            >
              <Tab label="Portfolio Activity" value={1} />
              <Tab label="Livestream" value={2} />
              <Tab label="Book Mentorship Session" value={3} />
            </Tabs>

            <WSTelegramCTA link={telegramLink} />
            <WSContactSupport />
          </div>
        </>
      )}

      <div className="ws-overview__tabs-content">
        {value === 0 && isMobile && (
          <div className="ws-overview-portfolios ws-overview-portfolios--mobile">
            {portfolioArr?.map((portfolio, index) => (
              <div
                key={index}
                className={`ws-overview-portfolio ${series === "everest" ? "ws-overview-portfolio--everest" : ""}`}
              >
                <WSPortfolioItem portfolio={portfolio} type={series} />
              </div>
            ))}
            <div className="simulated">*Results are simulated </div>
          </div>
        )}
        {value === 1 && (
          <WSPortfolioActivities
            adjustments={adjustments}
            loading={loadingPortfolio}
          />
        )}
        {value === 2 && (
          <WSLiveStream
            subTitle={subTitle}
            scheduleDate={scheduleDate}
            description={description}
            eventID={vimeoEventID}
            goBookMentorship={() => setValue(3)}
          />
        )}
        {value === 3 && (
          <WSBookMentorship tier={series} themeMode={themeMode} />
        )}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
});
const dispatchToProps = (dispatch) => ({
  fetchPortfolioData: (id, spark) => dispatch(portfolioLoad(id, spark)),
  fetchSubscribed: () => dispatch(fetchSubscribedPortfoliosRequest()),
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});
export default connect(stateToProps, dispatchToProps)(WealthSeriesOverview);
