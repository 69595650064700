export const changeLogs = [
  {
    id: 3,
    version: "2.15.0",
    date: "2025-03-10T00:00:00.000Z",
    title: "Wealth Series Lite Now Available",
    description:
      "- Wealth Series Lite combines our AI stock picker model with a user-customizable portfolio that gamifies into a leaderboard stage across other subscribers.\n- Gain exclusive access to our AI stock picks, refreshed on a biweekly basis.\n- Contact our sales team to find out more.",
    category: ["ui_ux"],
    status: "released",
    tags: ["Wealth Series Lite"],
  },
  {
    id: 2,
    version: "2.14.0",
    date: "2025-02-03T00:00:00.000Z",
    title: "Introducing Wealth Series: Your Journey to Smarter Investments",
    description:
      "- Wealth Series is a new product on TradeAlgo allowing users to select from 3 tiers of AI-generated portfolio packs: Atlas, Titan, Everest.\n- Gain exclusive access to our investment strategy—such as high growth, low volatility, deep value, diversified, or balanced—we prioritize top stock picks using a sophisticated system of criteria to construct and maintain a well-optimized portfolio.\n- Contact our sales team to find out more.",
    category: ["ui_ux"],
    status: "released",
    tags: ["Wealth Series Premium"],
  },
  {
    id: 1,
    version: "2.13.0",
    date: "2025-01-06T00:00:00.000Z",
    title: " Introducing TradeGPT Trade Assistant",
    description:
      "- TradeGPT Trade Assistant is now available on all pages, to enhance your TradeAlgo experience.\n- TradeGPT Trade Assistant provides real-time insights, market analysis, and personalized responses to your trading queries.",
    category: ["ui_ux"],
    status: "released",
    tags: ["TradeGPT"],
  },
];
