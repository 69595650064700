import React from "react";
import LiveSessionsTabs from "../common/Tabs/LiveSessionsTabs";
import moment from "moment";
import { heightRegex, widthRegex } from "../Tutorials/TutorialsContainer";
import { makeStyles } from "@material-ui/core";
import "./previousVideos.scss";
import { connect } from "react-redux";
import Pagination from "../PreviousStreams/Pagination";
import VideoCardSkeleton from "./VideoCardSkeleton";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));
function PreviousVideos({
  tab,
  setTab,
  videos,
  currentVideo,
  setCurrentVideo,
  loading,
  videoKey,
  customPageSize = 9,
  titleFilter = null,
}) {
  const classes = useStyles();
  function videoSetter(el) {
    setCurrentVideo({
      live: false,
      url: el.embed.html
        .replace(widthRegex, 'width="100%"')
        .replace(heightRegex, 'height="600px"'),
      date: moment(el.last_user_action_event_date).format("LLL"),
      description: el.description,
      duration: moment
        .duration(el.duration, "seconds")
        .format("h [hrs], m [min]"),
      link: el.link,
    });
  }
  const filteredVideos = useMemo(() => {
    const duration = videos.filter((i) => !!i.duration);
    if (titleFilter) {
      return duration.filter((i) => i.name === titleFilter);
    }
    return duration;
  }, [titleFilter, videos]);
  return (
    <>
      <div style={{ padding: "0 15px" }}>
        <LiveSessionsTabs
          tutorials={false}
          activeTab={tab}
          setActiveTab={setTab}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      </div>

      <div className="previous-videos-page">
        {videos && !loading
          ? filteredVideos.map((el, index) => (
              <div
                key={index}
                className={`previous-video`}
                onClick={() => {
                  videoSetter(el, true);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <div
                  className={
                    classes.background +
                    ` previous-video-inner-content ${el.link === currentVideo.link ? "active-video-content" : ""}`
                  }
                >
                  <div>
                    <img
                      className="previous-video-thumbnail"
                      src={el.pictures.sizes[3].link_with_play_button}
                      alt="video-thumbnail"
                    />
                  </div>
                  <div className="previous-video-title">
                    {moment(el.last_user_action_event_date).format("llll")}
                  </div>
                  <div className="previous-video-length">
                    {moment
                      .duration(el.duration, "seconds")
                      .format("h[h]:m[m]")
                      .replace(/:0s$/, "")
                      .replace(/:0m$/, "")
                      .replace(":", " ")}
                  </div>
                </div>
              </div>
            ))
          : Array.from({ length: 9 }).map((el, i) => (
              <VideoCardSkeleton key={i} />
            ))}
      </div>
      <div className="flex-center previous-videos-pagination">
        <Pagination customPageSize={customPageSize} videoKey={videoKey} />
      </div>
    </>
  );
}
const stateToProps = (state) => ({
  videos: state.streams.streams,
  loading: state.streams.loading,
});

export default connect(stateToProps, null)(PreviousVideos);
