import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "@mui/material";
import "./ReleaseNotes.scss";
import { changeLogs } from "./changeLogs";
import moment from "moment";

const generateIdFromTitle = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
};

const ReleaseNotes = () => {
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    const initialId =
      hash ||
      (changeLogs.length ? generateIdFromTitle(changeLogs[0].title) : "");
    setActiveId(initialId);
    if (hash) {
      scrollToElement(hash);
    }
  }, [changeLogs]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMouseEnter = (e) => {
    if (e.currentTarget && e.currentTarget.id) {
      const id = e.currentTarget.id.replace("log-", "");
      setActiveId(id);
      scrollToElement(`link-${id}`);
    }
  };

  const handleLinkClick = (id) => {
    setActiveId(id);
    window.history.pushState(null, "", `#${id}`);
    scrollToElement(`log-${id}`);
    scrollToElement(`link-${id}`);
  };

  return (
    <div className="release-notes-page scrollable-section">
      <div className="release-notes-header">
        <div className="release-notes-title">Release Notes</div>
        <div className="release-notes-subtitle">
          See the latest features, improvements, and product updates by the
          TradeAlgo Team.
        </div>
      </div>

      <div className="release-notes-content">
        <div className="release-notes-left scrollable-section">
          {changeLogs.map((changeLog) => {
            const logId = generateIdFromTitle(changeLog.title);
            return (
              <div
                className="change-log"
                id={`log-${logId}`}
                key={logId}
                onMouseEnter={onMouseEnter}
              >
                <div className="change-log-header">
                  <h3 id={logId} className="change-log-title">
                    {changeLog.title}
                  </h3>
                  <div className="change-log-date">
                    {moment.utc(changeLog.date).format("MMM D, YYYY")} • v
                    {changeLog.version}
                  </div>
                </div>

                <div className="change-log-tags">
                  {changeLog.tags.map((tag, index) => (
                    <div className="change-log-tag" key={index}>
                      {tag}
                    </div>
                  ))}
                </div>
                <div className="change-log-markdown">
                  <ReactMarkdown>{changeLog.description}</ReactMarkdown>
                </div>
              </div>
            );
          })}
        </div>

        <div className="release-notes-right scrollable-section">
          {changeLogs.map((changeLog) => {
            const logId = generateIdFromTitle(changeLog.title);
            return (
              <Link
                href={`#${logId}`}
                className={`change-log-nav ${activeId === logId ? "active-nav" : ""}`}
                key={logId}
                id={`link-${logId}`}
                onClick={() => handleLinkClick(logId)}
              >
                <div className="change-log-nav-title">{changeLog.title}</div>
                <div className="change-log-nav-date">
                  {moment.utc(changeLog.date).format("MMM D, YYYY")}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReleaseNotes;
